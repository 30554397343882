import React from 'react'


function Hero() {
    return (
        <section className="w-full sectionServices h-fit xl:py-40 py-14 relative bg-gray-50">
            {/* Overlay */}
            {/* <div class="absolute inset-0 bg-gray-900 z-10 opacity-40"></div> */}
            {/* Background Image */}
            {/* <div class="absolute inset-0 bg-cover bg-center" style="background-image: url('./images/heroImage.webp');">
      </div> */}
            {/* Content */}
            <div className="relative z-10 w-full h-full">
                <div className="flex xl:gap-10 w-full h-full justify-center xl:flex-row flex-col xl:-mt-[40px] mt-[30px] px-2 sm:px-4 md:px-8 lg:px-16 xl:px-24 2xl:px-64 items-center">

                    <div className="flex flex-col w-full xl:w-3/4 text-center  h-full justify-center xl:gap-9 gap-10 pb-14 xl:pb-0  items-center">
                        <h4 className="font-bold leading-[44px] xl:leading-[64px] text-[34px] xl:text-[48px] text-neutral-800">
                            Boost your business without limits!
                        </h4>

                       {/* <div className="flex xl:hidden justify-center w-full xl:w-1/2 items-center">
                            <img src={photo} className='w-full' alt='image' />
                        </div>*/}

                        <p className="font-normal leading-[36px] text-[15px] xl:text-[18px] text-neutral-800">
                            Join <b className='animate-pulse'>ShopyAds Agency</b> for premium services and elevate your business! Perfect for those seeking stable, scalable, and downtime-free solutions.
                        </p>

                        <a
                            href="#form"
                            className="text-neutral-800 rounded-full border-b-[4px] hover:border-b-[1px] hover:scale-105 scroll-link uppercase font-bold leading-[22px] bg-white hover:text-neutral-800 text-[17px] flex gap-2 items-center justify-center w-fit border border-neutral-800 py-3 px-10 transition-all duration-300"
                        >
                            Work with us
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="size-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                />
                            </svg>
                        </a>
                    </div>

                    {/*<div className="xl:flex hidden justify-end w-full items-center">
                        <img src={photo} className='w-full' alt='image' />
                    </div>*/}
                </div>
            </div>






        </section>

    )
}

export default Hero