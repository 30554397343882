import React from 'react'
import photo from '../images/shopy.png'
import { Link } from 'react-router-dom'

function Services() {
    return (
        <section id="Services" className="w-full sectionServices h-full px-3 py-10 xl:py-24 relative bg-gray-100">
            {/* Overlay */}
            {/* <div class="absolute inset-0 bg-black z-10 opacity-60"></div> */}
            {/* Background Image */}
            {/* <div class="absolute inset-0 bg-cover bg-center" style="background-image: url('./images/stars.avif');">
      </div> */}
            {/* Content */}
            <div className="relative flex flex-col gap-10 justify-center px-2 sm:px-10 items-center z-10 w-full h-full">
                <div className="flex relative justify-center gap-7 items-center w-full h-full flex-col ">

                    <h1 className="font-[800] text-center capitalize leading-[44px]  text-[40px] xl:text-[57px] text-neutral-800">
                        Our Services       </h1>
                    <h4 className="font-[400] xl:w-1/2 text-center uppercase leading-[26px] text-[17px] text-neutral-800">
                        We offer tailored services to help our clients advance and develop their projects.

                    </h4>
                </div>
                <div className="grid xl:grid-cols-4 md:grid-cols-2 h-full gap-5  w-full place-items-center ">
                    <Link to='/PaymentGateaway' className="flex relative flex-col gap-5  h-full hover:scale-105 justify-start py-8 px-6 items-start shadow border-black border-t-[1.8px] border-l-[1.8px] border-r-[4px] border-b-[4px]  rounded-lg bg-white hover:border-black transition-all duration-200 ">
                        <div className="flex z-30 border  rounded-xl border-gray-200 shadow px-3 py-3  justify-center items-center  gap-3 w-full   ">
                            <p className="font-[600] capitalize text-center  leading-[32px] text-[20px] text-black">
                                Full Pack Payment gateways
                            </p>
                        </div>
                        <p className="font-[400] z-30 text-center leading-[22px] text-[15px] text-neutral-600">We provide a complete setup with a verified bank  for payouts, all major payment gateways (including Klarna Pay Now and Pay Later), and local payment options. We also offer ready-to-use LTD, LLC, and HK company structures. Plus, our post-sale support ensures you are protected and supported.

                        </p>

                    </Link>

                    <Link to='/AdAcounts' className="flex flex-col  h-full gap-5 hover:scale-105 justify-start py-8 px-6 items-start shadow border-black border-t-[1.8px] border-l-[1.8px] border-r-[4px] border-b-[4px]  rounded-lg bg-gray-50 hover:border-black transition-all duration-200 ">
                        <div className="flex border  rounded-xl border-gray-200 shadow  px-3 py-3  justify-center items-start  gap-3 w-full">

                            <p className="font-[600] text-center  leading-[32px] text-[20px] text-black">
                                Whitelisted Premium Agency Ad Accounts

                            </p>
                        </div>

                        <p className="font-[400]  text-center leading-[22px] text-[15px] text-neutral-800">We provide premium and trusted agency ad accounts, including Meta, TikTok, Snapchat, and Google, ensuring reliable performance, enhanced security, and full compliance for your advertising needs.

                        </p>

                    </Link>
                    <Link to='/AdvancedStructure' className="flex flex-col  h-full gap-5 hover:scale-105 justify-start py-8 px-6 items-start shadow border-black border-t-[1.8px] border-l-[1.8px] border-r-[4px] border-b-[4px]  rounded-lg bg-gray-50 hover:border-black transition-all duration-200 ">
                        <div className="flex border  rounded-xl border-gray-200 shadow px-3 py-3  justify-center items-start  gap-3 w-full  ">

                            <p className="font-[600] text-center  leading-[32px] text-[20px] text-black">
                                Exclusive High-End Meta Solutions

                            </p>
                        </div>

                        <p className="font-[400]  text-center leading-[22px] text-[15px] text-neutral-800">We offer aged & reinstated Facebook profiles, BM, and Pages with an Advanced Structure Setup. Additionally, Our Elite VIP Insights & Solutions include financial management, license registration, account recovery, and competitor insights to enhance your ad success.
                        </p>

                    </Link>
                    <Link to='/SocialMediaServices' className="flex flex-col h-full gap-5 hover:scale-105 justify-start py-8 px-6 items-start shadow border-black border-t-[1.8px] border-l-[1.8px] border-r-[4px] border-b-[4px] rounded-lg bg-gray-50 hover:border-black transition-all duration-200 ">
                        <div className="flex border  rounded-xl border-gray-200 shadow px-3 py-3  justify-center  items-center  gap-3 w-full ">

                            <p className="font-[600] text-center  leading-[32px] text-[20px] text-black">

                                Digital Marketing Services

                            </p>
                        </div>

                        <p className="font-[400]  text-center leading-[22px] text-[15px] text-neutral-800">We offer a full range of social media services, including ad video production, media buying, and brand store creation. Our expertise ensures effective campaigns and a strong online presence for your brand.</p>

                    </Link>


                </div>
                <div className="flex flex-col gap-3 justify-center items-center">
                    <a href="#form" className="text-neutral-800 rounded-full border-b-[4px] hover:border-b-[1px] animate-pulse hover:scale-105 scroll-link uppercase font-[700] leading-[22px] hover:bg-white hover:text-gray-900  text-[17px] flex gap-2 items-center justify-center w-fit border border-neutral-800 py-3 xl:px-10 px-5 transition-all duration-300">
                        Boost Your Business With Us
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                    </a>



                    <div className="flex justify-start  items-center ">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-5 text-yellow-300">
                            <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-5 text-yellow-300">
                            <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-5 text-yellow-300">
                            <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-5 text-yellow-300">
                            <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-5  text-black">
                            <defs>
                                <linearGradient id="half-yellow" x1="0%" y1="0%" x2="100%" y2="0%">
                                    <stop offset="50%" style={{ stopColor: "#2A7F93", stopOpacity: 1 }} />
                                    <stop offset="50%" style={{ stopColor: "gray", stopOpacity: 1 }} />
                                </linearGradient>
                            </defs>
                            <path fill="url(#half-yellow)" fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
                        </svg>

                        <p className="font-[500] capitalize leading-[15px] text-[15px] text-neutral-800 ml-2"> Based
                            on 3500+
                            Clients</p>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default Services