import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, BrowserRouter } from 'react-router-dom';
import './App.css';
import Nav from './components/Nav';
import Footer from './components/Footer';
import Home from './pages/Home';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsofService from './pages/TermsofService';
import CookiePolicy from './pages/CookiePolicy';
import AdAcounts from './pages/AdAcounts';
import PaymentGateaway from './pages/PaymentGateaway';
import SocialMediaServices from './pages/SocialMediaServices';
import AdvancedStructure from './pages/AdvancedStructure';
function App() {
  return (
    <BrowserRouter>
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/TermsofService" element={<TermsofService />} />
        <Route path="/CookiePolicy" element={<CookiePolicy />} />
        <Route path="/AdAcounts" element={<AdAcounts />} />
        <Route path="/AdvancedStructure" element={<AdvancedStructure />} />
        <Route path="/PaymentGateaway" element={<PaymentGateaway />} />
        <Route path="/SocialMediaServices" element={<SocialMediaServices />} />

      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
